import request from '@/utils/request'

// 查询海报管理列表
export function listPartnerPoster(query) {
  return request({
    url: '/work/partnerPoster/list',
    method: 'get',
    params: query
  })
}

// 查询海报管理详细
export function getPartnerPoster(id) {
  return request({
    url: '/work/partnerPoster/' + id,
    method: 'get'
  })
}

// 新增海报管理
export function addPartnerPoster(data) {
  return request({
    url: '/work/partnerPoster',
    method: 'post',
    data: data
  })
}

// 修改海报管理
export function updatePartnerPoster(data) {
  return request({
    url: '/work/partnerPoster/update',
    method: 'post',
    data: data
  })
}

// 删除海报管理
export function delPartnerPoster(id) {
  return request({
    url: '/work/partnerPoster/remove/' + id,
    method: 'get'
  })
}

// 导出海报管理
export function exportPartnerPoster(query) {
  return request({
    url: '/work/partnerPoster/export',
    method: 'get',
    params: query
  })
}