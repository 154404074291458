import request from '@/utils/request'

// 查询小程序海报管理列表
export function listWxappPartnerPoster(query) {
  return request({
    url: '/work/partnerPoster/getPosterListSys',
    method: 'get',
    params: query
  })
}
